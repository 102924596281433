<template>
  <div>
    <v-container>
      <v-breadcrumbs
        :items="breadcrumbs"
        class="pa-0 pb-2"
        divider=">"
      ></v-breadcrumbs>
      <v-row align="center">
        <v-col>
          <h1>
            {{ delivery.parent.customer.full_name }} -
            {{ delivery.parent.identifier }}{{ delivery.identifier }}
          </h1>
        </v-col>
        <v-col cols="2">
          <v-row align="center" justify="end" no-gutters>
            <v-btn
              depressed
              color="accent"
              :to="{
                name: 'module-paw-subscriptions-individual',
                params: { subscriptionId: delivery.parent.id },
              }"
            >
              <v-icon left>mdi-rotate-right</v-icon>View Subscription
            </v-btn>
          </v-row>
        </v-col>
      </v-row>
      <div>
        <v-row>
          <v-col cols="8">
            <v-card>
              <v-toolbar flat dark dense color="accent">
                <v-toolbar-title>Delivery Details</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn
                  small
                  depressed
                  @click="openEditDetailsForm()"
                  v-if="isEditable"
                >
                  <v-icon left>mdi-pencil</v-icon>Edit Details
                </v-btn>
              </v-toolbar>
              <v-simple-table>
                <tbody>
                  <tr>
                    <th>Status</th>
                    <td>
                      <v-chip
                        label
                        small
                        :color="
                          getDeliveryStatusColor(delivery.delivery_status)
                        "
                        >{{
                          getDeliveryStatusLabel(delivery.delivery_status)
                        }}</v-chip
                      >
                    </td>
                  </tr>
                  <tr>
                    <th>Products</th>
                    <td>
                      <ul v-if="delivery.products">
                        <li
                          v-for="product in delivery.products"
                          v-bind:key="product.id"
                        >
                          {{ product.name }} ({{ product.pivot.volume }}kg)
                        </li>
                      </ul>
                    </td>
                  </tr>
                  <tr>
                    <th>Delivery Cost</th>
                    <td>£{{ delivery.delivery_cost }}</td>
                  </tr>
                  <tr>
                    <th>Total Cost</th>
                    <td>£{{ delivery.cost }}</td>
                  </tr>
                  <tr>
                    <th>Payment Date</th>
                    <td>{{ delivery.formatted_dates.process_date }}</td>
                  </tr>
                  <tr>
                    <th>Order Date</th>
                    <td>{{ delivery.parent.formatted_dates.created_at }}</td>
                  </tr>
                  <tr>
                    <th>Dispatch Date/Time</th>
                    <td></td>
                  </tr>
                  <tr>
                    <th>GA Ref</th>
                    <td></td>
                  </tr>
                </tbody>
              </v-simple-table>
            </v-card>
          </v-col>
          <v-col cols="4">
            <v-card>
              <v-toolbar flat dark dense color="accent">
                <v-toolbar-title>Addresses</v-toolbar-title>
              </v-toolbar>
              <v-card-text>
                <v-card outlined class="mb-3">
                  <v-card-title class="pt-2 pb-2">
                    <span class="subtitle-1">Delivery Address</span>
                    <v-spacer></v-spacer>
                    <v-btn
                      x-small
                      depressed
                      dark
                      @click="openChangeAddress('delivery')"
                      v-if="isEditable"
                      >Change</v-btn
                    >
                  </v-card-title>
                  <v-divider></v-divider>
                  <v-card-text>
                    <div>
                      <strong>{{ delivery.shipping_address.line_1 }}</strong>
                    </div>
                    <div v-if="delivery.shipping_address.line_2">
                      {{ delivery.shipping_address.line_2 }}
                    </div>
                    <div v-if="delivery.shipping_address.line_3">
                      {{ delivery.shipping_address.line_3 }}
                    </div>
                    <div v-if="delivery.shipping_address.city">
                      {{ delivery.shipping_address.city }}
                    </div>
                    <div v-if="delivery.shipping_address.county">
                      {{ delivery.shipping_address.county }}
                    </div>
                    <div v-if="delivery.shipping_address.postcode">
                      {{ delivery.shipping_address.postcode }}
                    </div>
                    <div v-if="delivery.shipping_address.country">
                      {{ delivery.shipping_address.country }}
                    </div>
                  </v-card-text>
                </v-card>
                <v-card outlined>
                  <v-card-title class="pt-2 pb-2">
                    <span class="subtitle-1">Billing Address</span>
                    <v-spacer></v-spacer>
                    <v-btn
                      x-small
                      depressed
                      dark
                      @click="openChangeAddress('billing')"
                      v-if="isEditable"
                      >Change</v-btn
                    >
                  </v-card-title>
                  <v-divider></v-divider>
                  <v-card-text>
                    <div>
                      <strong>{{ delivery.billing_address.line_1 }}</strong>
                    </div>
                    <div v-if="delivery.billing_address.line_2">
                      {{ delivery.billing_address.line_2 }}
                    </div>
                    <div v-if="delivery.billing_address.line_3">
                      {{ delivery.billing_address.line_3 }}
                    </div>
                    <div v-if="delivery.billing_address.city">
                      {{ delivery.billing_address.city }}
                    </div>
                    <div v-if="delivery.billing_address.county">
                      {{ delivery.billing_address.county }}
                    </div>
                    <div v-if="delivery.billing_address.postcode">
                      {{ delivery.billing_address.postcode }}
                    </div>
                    <div v-if="delivery.billing_address.country">
                      {{ delivery.billing_address.country }}
                    </div>
                  </v-card-text>
                </v-card>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </div>
    </v-container>
    <v-dialog v-model="changeAddressForm.dialog" max-width="400px">
      <v-card>
        <v-form @submit.prevent="changeAddressSubmit" method="post">
          <v-card-title class="headline"
            >Change
            {{ changeAddressForm.type === "delivery" ? "Delivery" : "Billing" }}
            Address</v-card-title
          >
          <v-divider></v-divider>
          <v-card-text>
            <v-radio-group v-model="changeAddressForm.address_id">
              <v-radio
                v-for="address in delivery.parent.customer.addresses"
                v-bind:key="address.id"
                :value="address.id"
                class="align-start"
              >
                <template v-slot:label>
                  <div class="d-flex flex-column mb-2">
                    <div>
                      <strong>{{ address.line_1 }}</strong>
                    </div>
                    <div v-if="address.line_2">{{ address.line_2 }}</div>
                    <div v-if="address.line_3">{{ address.line_3 }}</div>
                    <div v-if="address.city">{{ address.city }}</div>
                    <div v-if="address.county">{{ address.county }}</div>
                    <div v-if="address.postcode">{{ address.postcode }}</div>
                    <div v-if="address.country">{{ address.country }}</div>
                  </div>
                </template>
              </v-radio>
            </v-radio-group>
            <!-- <v-btn color="accent" depressed @click="openCreateAddress()">Add New Address</v-btn> -->
            <v-checkbox
              v-model="changeAddressForm.update_customer"
              label="Would you like to update the Customer's address as well?"
              hide-details
            ></v-checkbox>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="secondary"
              text
              @click="changeAddressForm.dialog = false"
              >Close</v-btn
            >
            <v-btn
              color="accent"
              text
              type="submit"
              :loading="changeAddressForm.loading"
              >Save</v-btn
            >
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
    <v-dialog v-model="addAddressForm.dialog" max-width="600px">
      <v-card>
        <v-form @submit.prevent="saveAddress" method="post">
          <v-card-title>
            <span class="headline"
              >{{
                addAddressForm.isEditing
                  ? "Edit " + addAddressForm.address.line_1
                  : "Add New"
              }}
              Address</span
            >
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <v-text-field
              label="Address Line 1"
              type="text"
              v-model="addAddressForm.fields.line_1"
              required
              outlined
              dense
              :error="addAddressForm.errors.hasOwnProperty('line_1')"
              :error-messages="addAddressForm.errors['line_1']"
            ></v-text-field>
            <v-text-field
              label="Address Line 2"
              type="text"
              v-model="addAddressForm.fields.line_2"
              required
              outlined
              dense
              :error="addAddressForm.errors.hasOwnProperty('line_2')"
              :error-messages="addAddressForm.errors['line_2']"
            ></v-text-field>
            <v-text-field
              label="Address Line 3"
              type="text"
              v-model="addAddressForm.fields.line_3"
              required
              outlined
              dense
              :error="addAddressForm.errors.hasOwnProperty('line_3')"
              :error-messages="addAddressForm.errors['line_3']"
            ></v-text-field>
            <v-text-field
              label="City"
              type="text"
              v-model="addAddressForm.fields.city"
              required
              outlined
              dense
              :error="addAddressForm.errors.hasOwnProperty('city')"
              :error-messages="addAddressForm.errors['city']"
            ></v-text-field>
            <v-text-field
              label="County"
              type="text"
              v-model="addAddressForm.fields.county"
              required
              outlined
              dense
              :error="addAddressForm.errors.hasOwnProperty('county')"
              :error-messages="addAddressForm.errors['county']"
            ></v-text-field>
            <v-text-field
              label="Postcode"
              type="text"
              v-model="addAddressForm.fields.postcode"
              required
              outlined
              dense
              :error="addAddressForm.errors.hasOwnProperty('postcode')"
              :error-messages="addAddressForm.errors['postcode']"
            ></v-text-field>
            <v-text-field
              label="Country"
              type="text"
              v-model="addAddressForm.fields.country"
              required
              outlined
              dense
              :error="addAddressForm.errors.hasOwnProperty('country')"
              :error-messages="addAddressForm.errors['country']"
            ></v-text-field>
            <v-checkbox
              v-model="addAddressForm.fields.is_shipping"
              label="Set as Delivery Address"
              hide-details
            ></v-checkbox>
            <v-checkbox
              v-model="addAddressForm.fields.is_billing"
              label="Set as Billing Address"
              hide-details
            ></v-checkbox>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="secondary" text @click="resetAddressForm()"
              >Close</v-btn
            >
            <v-btn
              color="accent"
              text
              type="submit"
              :loading="addAddressForm.loading"
              >Save</v-btn
            >
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
    <v-dialog v-model="editDetailsForm.dialog" max-width="600px">
      <v-card>
        <v-form @submit.prevent="saveEditDetailsForm" method="post">
          <v-card-title class="headline">Edit Delivery's Details</v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <v-alert type="warning" outlined class="mb-6">
              By updating these delivery details, you will
              <strong>not</strong> be updating any of the subscription's
              details. If you want these changes to be permanent (i.e. saved
              against all future deliveries) please update the subscription
              directly
            </v-alert>
            <v-menu
              ref="editDeliveryDate"
              v-model="editDetailsForm.deliveryDateMenu"
              :close-on-content-click="false"
              max-width="290px"
              min-width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="editDeliveryDateFormatted"
                  label="Delivery Date"
                  prepend-inner-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                  outlined
                  :error="
                    editDetailsForm.errors.hasOwnProperty(
                      'fields.estimated_delivery'
                    )
                  "
                  :error-messages="
                    editDetailsForm.errors['fields.estimated_delivery']
                  "
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="editDetailsForm.fields.estimated_delivery"
                :min="editDetailsForm.now"
                @input="editDetailsForm.deliveryDateMenu = false"
              ></v-date-picker>
            </v-menu>
            <v-select
              label="Volume"
              v-model="editDetailsForm.fields.volume"
              :items="volumes"
              item-text="name"
              item-value="value"
              outlined
              @change="refreshPrice"
              append-outer-icon="mdi-weight-kilogram"
              :error="editDetailsForm.errors.hasOwnProperty('fields.volume')"
              :error-messages="editDetailsForm.errors['fields.volume']"
            ></v-select>
            <v-select
              label="Products"
              v-model="editDetailsForm.fields.products"
              :items="products"
              item-text="name"
              item-value="id"
              outlined
              multiple
              v-on:input="productLimiter"
              @change="refreshPrice"
              :error="editDetailsForm.errors.hasOwnProperty('fields.products')"
              :error-messages="editDetailsForm.errors['fields.products']"
            ></v-select>
            <v-text-field
              v-model="editDetailsForm.fields.cost"
              label="Renewal Charge"
              type="number"
              outlined
              prepend-inner-icon="mdi-currency-gbp"
              :loading="editDetailsForm.costLoading"
              :readonly="editDetailsForm.costDisabled"
              :error="editDetailsForm.errors.hasOwnProperty('fields.cost')"
              :error-messages="editDetailsForm.errors['fields.cost']"
            >
              <template v-slot:append>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on: onTooltip }">
                    <v-icon
                      v-on="onTooltip"
                      @click="
                        editDetailsForm.costDisabled =
                          !editDetailsForm.costDisabled
                      "
                      >{{
                        editDetailsForm.costDisabled
                          ? "mdi-pencil-off"
                          : "mdi-pencil"
                      }}</v-icon
                    >
                  </template>
                  <span>{{
                    editDetailsForm.costDisabled
                      ? "Edit Cost"
                      : "Stop Editing Cost"
                  }}</span>
                </v-tooltip>
              </template>
            </v-text-field>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="secondary"
              text
              @click="editDetailsForm.dialog = false"
              >Close</v-btn
            >
            <v-btn
              color="accent"
              text
              type="submit"
              :loading="editDetailsForm.loading"
              :disabled="editDetailsForm.disabled"
              >Save</v-btn
            >
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { paw } from "../../mixins/";

export default {
  mixins: [paw],

  data() {
    return {
      breadcrumbs: [
        {
          text: "Paw Dog Food",
          disabled: false,
          to: { name: "app-dashboard", params: { id: this.$route.params.id } },
        },
        {
          text: "Deliveries",
          disabled: false,
          exact: true,
          to: {
            name: "module-paw-deliveries",
            params: { id: this.$route.params.id },
          },
        },
      ],
      changeAddressForm: {
        dialog: false,
        loading: false,
        errors: {},
        address_id: null,
        type: null,
        update_customer: false,
      },
      addAddressForm: {
        dialog: false,
        loading: false,
        errors: {},
        fields: {
          line_1: "",
          line_2: "",
          line_3: "",
          city: "",
          county: "",
          postcode: "",
          country: "",
          is_shipping: false,
          is_billing: false,
        },
      },
      editDetailsForm: {
        dialog: false,
        loading: false,
        disabled: false,
        errors: {},
        deliveryDateMenu: false,
        now: new Date().toISOString().slice(0, 10),
        costLoading: false,
        costDisabled: true,
        fields: {
          volume: null,
          products: null,
          cost: null,
          estimated_delivery: null,
        },
      },
    };
  },

  computed: {
    delivery() {
      return this.$store.state.paw["delivery"];
    },

    editDeliveryDateFormatted() {
      return this.formatDate(this.editDetailsForm.fields.estimated_delivery);
    },

    breeds() {
      return this.$store.state.paw["breeds"];
    },

    products() {
      return this.$store.state.paw["products"];
    },

    isEditable() {
      return this.delivery.is_editable;
    },
  },

  methods: {
    loadDelivery: function () {
      const deliveries = this.$store.state.paw["deliveries"];
      const id = parseInt(this.$route.params.deliveryId);

      if (deliveries.some((d) => d.id === id)) {
        this.delivery = deliveries.find((d) => d.id === id);
      } else {
        this.$router.push({
          name: "module-paw-deliveries",
          params: { id: this.$route.params.id },
        });
      }

      this.breadcrumbs.push({
        text: this.delivery.subscription.identifier + this.delivery.identifier,
        disabled: true,
      });
    },
    openChangeAddress: function (type) {
      this.changeAddressForm.type = type;
      this.changeAddressForm.address_id =
        type === "delivery"
          ? this.delivery.shipping_address.id
          : this.delivery.billing_address.id;
      this.changeAddressForm.dialog = true;
    },
    openCreateAddress: function () {
      if (this.changeAddressForm.type === "delivery") {
        this.addAddressForm.fields.is_shipping = true;
      } else if (this.changeAddressForm === "billing") {
        this.addAddressForm.fields.is_billing = true;
      }

      this.changeAddressForm.dialog = false;
      this.addAddressForm.dialog = true;
    },
    resetAddressForm: function () {
      this.addAddressForm = {
        dialog: false,
        loading: false,
        errors: {},
        fields: {
          line_1: "",
          line_2: "",
          line_3: "",
          city: "",
          county: "",
          postcode: "",
          country: "",
          is_shipping: false,
          is_billing: false,
        },
      };
    },
    changeAddressSubmit: function () {
      const appId = this.$route.params.id;
      const deliveryId = this.$route.params.deliveryId;
      this.changeAddressForm.loading = true;

      this.$store
        .dispatch("paw/editDeliveryAddress", {
          appId,
          deliveryId,
          type: this.changeAddressForm.type,
          address_id: this.changeAddressForm.address_id,
          update_customer: this.changeAddressForm.update_customer,
        })
        .then(() => {
          this.changeAddressForm = {
            dialog: false,
            loading: false,
            errors: {},
            address_id: null,
            type: null,
            update_customer: false,
          };
        })
        .catch((err) => {
          this.changeAddressForm.errors = err.response.data.errors;
          this.changeAddressForm.loading = false;
        });
    },
    productLimiter: function (p) {
      const limit = this.editDetailsForm.fields.volume === 24 ? 2 : 1;

      if (p.length > limit) {
        p.pop();
      }
    },
    openEditDetailsForm: function () {
      this.editDetailsForm.fields.estimated_delivery =
        this.delivery.estimated_delivery;
      this.editDetailsForm.fields.volume =
        this.delivery.products.length > 1
          ? this.delivery.products[0].pivot.volume *
            this.delivery.products.length
          : this.delivery.products[0].pivot.volume;
      this.editDetailsForm.fields.products = this.delivery.products.map((p) => {
        return p.id;
      });
      this.editDetailsForm.fields.cost = this.delivery.cost;
      this.editDetailsForm.dialog = true;
    },
    saveEditDetailsForm: function () {
      const appId = this.$route.params.id;
      const deliveryId = this.$route.params.deliveryId;
      const fields = this.editDetailsForm.fields;

      this.editDetailsForm.loading = true;
      this.editDetailsForm.errors = {};

      this.$store
        .dispatch("paw/editDelivery", {
          appId,
          deliveryId,
          fields,
        })
        .then(() => {
          this.editDetailsForm.loading = false;
          this.editDetailsForm.dialog = false;
        })
        .catch((err) => {
          this.editDetailsForm.errors = err.response.data.errors;
          this.editDetailsForm.loading = false;
        });
    },
    refreshPrice: function () {
      this.editDetailsForm.costLoading = true;
      this.editDetailsForm.disabled = true;
      this.getUpdatedPrice(
        this.editDetailsForm.fields.volume,
        this.editDetailsForm.fields.products
      )
        .then((result) => {
          this.editDetailsForm.fields.cost = result;
          this.editDetailsForm.costLoading = false;
          this.editDetailsForm.disabled = false;
        })
        .catch(() => {
          this.editDetailsForm.costLoading = false;
          this.editDetailsForm.disabled = false;
        });
    },
  },
};
</script>
